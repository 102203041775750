<template>
	<div class="book-info-repository">
		<div class="filter" v-if="indexData.length > 1">
			<div class="filter-style">
				<cust-select
					v-model="filterOrder"
					:optionList="indexData"
					@change="filterOrderChange"
				>
					<template slot="option" slot-scope="{ item }">{{
						item.display_name
					}}</template>
				</cust-select>
				<!--<btn-with-icon
					:style="indexStyle"
					icon-name="ellipsis-h"
					@click="icselectorModalShow = true"
					name="進階分類"
				></btn-with-icon>-->
			</div>
		</div>
		<bookinfo-repository :datas="tableData" />
		<index-category-selector
			:modalShow="icselectorModalShow"
			@closeModal="icselectorModalShow = false"
			@selected="selectedIndexCategory($event)"
		/>
	</div>
</template>

<script>
import { bookInfoAction } from "@/api/ebookSystem.js";

export default {
	components: {
		bookinfoRepository: () => import("../components/bookinfoRepository2.vue"),
		btnWithIcon: () => import("@/components/buttonWithIcon.vue"),
		custSelect: () => import("@/components/custSelect.vue"),
		indexCategorySelector: () =>
			import("@/components/components/indexCategorySelector.vue")
	},
	data() {
		return {
			indexStyle: {
				margin: "0 0 0 20px",
				"white-space": "nowrap"
			},
			icselectorModalShow: false,
			ic_id: 0,
			ic_name: "不分類",
			tableData: [],
			filterOrder: 0,
			indexData: [
				{
					display_name: "總類",
					value: "1"
				},
				{
					display_name: "哲學",
					value: "2"
				},
				{
					display_name: "宗教",
					value: "3"
				},
				{
					display_name: "科學",
					value: "4"
				},
				{
					display_name: "應用科學",
					value: "5"
				},
				{
					display_name: "社會科學",
					value: "6"
				},
				{
					display_name: "史地類",
					value: "7"
				},
				{
					display_name: "世界史地",
					value: "8"
				},
				{
					display_name: "語言文學",
					value: "9"
				},
				{
					display_name: "藝術",
					value: "10"
				},
				{
					display_name: "其他",
					value: "0"
				}
			]
		};
	},
	mounted() {
		this.filterOrder = this.$route.params.idx;
		this.getData();
	},
	methods: {
		filterOrderChange(val) {
			const idx = val;
			this.$router.push({ name: this.$route.name, params: { idx } });
			this.getData();
		},
		getData() {
			this.pk = this.$route.params.idx;
			bookInfoAction
				.books([this.pk])
				.then(res => {
					this.tableData = [];
					_.each(res.data, o => {
						this.tableData.push({
							ISBN: o["ISBN"],
							bookname: o["bookname"],
							bookbinding: o["bookbinding"],
							order: o["order"],
							author: o["author"],
							house: o["house"],
							date: o["date"],
							action: o["ISBN"],
							finish_date: o["finish_date"]
						});
					});
				})
				.catch(res => {
					console.log(res);
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		selectedIndexCategory(data) {
			this.ic_id = data.id;
			this.ic_name = data.name;
			this.icselectorModalShow = false;
			this.filterOrder = String(data.path[0].id);
			const idx = data.id;
			this.$router.push({ name: this.$route.name, params: { idx } });
			this.getData();
		}
	}
};
</script>

<style scoped lang="scss">
.book-info-repository {
	margin: 0 -15px;
	.filter {
		display: flex;
		justify-content: flex-end;
		padding-right: 10px;
	}

	// *Purpose: Fix a wierd bug that .btnn-group functioning out of expect,
	// the width is too small than expect, don't know why.
	::v-deep .table-div {
		.btnn-group {
			min-width: 4.4em;
		}
	}
}
.filter-style {
	display: flex;
}
</style>
